<template>
  <div class="container-fluid multiple-tabs mt-3">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <Breadcrumb :parent_page="{title: 'التصنيفات' , path: '/categories'}" current_page="إضافة تصنيف"/>

      <button class="btn btn-publish mb-3" type="submit" @click="saveCategory">حفظ</button>

    </div>
    <el-card>
      <el-tabs v-model="activeTab">
        <el-tab-pane label="التصنيف" name="details">
          <Details :is-editable="false" :category-details="category"  @update="handleOnChange"/>
        </el-tab-pane>
        <el-tab-pane label="محركات البحث" name="seo">
          <SEO :seo-data="category" @update="handleOnChange"/>
        </el-tab-pane>
        <el-tab-pane label="منصات التواصل الاجتماعي" name="social-media">
          <SocialMediaTab :social-data="category" @update="handleOnChange"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>

</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import SEO from "@/views/categories/components/SEO";
import SocialMediaTab from "@/views/categories/components/socialMedia";
import Details from "@/views/categories/components/details";
import {addCategory} from "@/services/categories";
import {useToast} from "vue-toastification";

export default {
  name      : "Add_Category",
  components: {
    Details,
    SocialMediaTab,
    SEO,
    Breadcrumb
  },
  data()
  {
    return {
      toast: useToast(),
      activeTab: 'details',
      category: {
        name: '',
        color: '',
        parent_id: '',
        description: '',
        image: '',
        is_active: false,
        seo_name: '',
        seo_title: '',
        seo_description: '',
        social_title: '',
        social_description: '',
        social_image: '',
        show_in_nav: false,
        show_in_home: false,
      }
    }
  },
  methods: {
    handleOnChange(key, val)
    {
      this.category = {
        ...this.category,
        [key]: val
      }
    },
    saveCategory() {
      addCategory(this.category)
      .then(() => {
        this.$router.push('/categories')
        this.toast.success("تم الإضافة بنجاح", {
          position: "top-center",
        });
      })
      .catch(()=> {
        this.toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى", {
          position: "top-center",
        });
      })
    }
  }
}
</script>

<style lang="scss">
.el-tabs__nav {
  float: right;
}

[dir=rtl] .el-dialog__headerbtn {
  right: unset;
  left: 20px;
}
</style>
